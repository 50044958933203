import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import { ingresoUsuarioAccion } from '../../redux/usuarioDucks'
import { Image } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom';

const BtnAceceder = () => {
    const loading = useSelector(store => store.usuario.loading)
    const dispatch = useDispatch()
    return (
    <div>
             {/* <button 
                className="btn btn-bicci btn-bicci-prupura"
                onClick={() => dispatch(ingresoUsuarioAccion())}
                disabled={loading}>
                Acceso rapido con:  <span>{' '} </span>
                <Image className="brands" src='https://www.google.com/images/branding/googlelogo/1x/googlelogo_color_272x92dp.png' />
            </button>  */}
            <a href='https://play.google.com/store/apps/details?id=cool.mavericks.biccirider'>Puedes crear cuenta rider, descargando nuestra app Bicci Rider.
            </a>
            <div className="brandsContent">
            <a href='https://play.google.com/store/apps/details?id=cool.mavericks.biccirider'>
              <Image className="brandsStore" src="https://bicci.cl/wp-content/uploads/2020/07/GooglePlay-y-AppStore-2.png" />
              </a> 
              <a href='https://apps.apple.com/cl/app/bicci-delivery/id1526222925'>
              <Image className="brandsStore" src="https://bicci.cl/wp-content/uploads/2020/07/GooglePlay-y-AppStore-1.png" /> 
              </a> 
            </div>
    </div>
  )
}

export default BtnAceceder