import styled from "styled-components";
import React, { useState }  from 'react';
import Navbar from './NavbarWrapper';
import MenuButton  from "./MenuButton";

function Movilnavbar() {
    const HeaderWrapper = styled.header`
    @media only screen and (max-width: 780px) {  
            margin-top: -1.25rem;
            height: 10vh;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border:none;
            background-color: transparent;
            button{
                background-color: transparent;
                border:none;
                border-radius: 3px;
                background: #ededed;
            }
            .icon{
                font-size: 2em!important;
                color: aqua!important;
                border-radius: 3px;
                background: #ededed;
            }
            }
   @media only screen and (min-width: 780px) {  
            margin-top: -1.25rem;
            height: 10vh;
            display: flex;
            justify-content: space-between;
            align-items: center;
        `;

const [open, setOpen] = useState(false);

const handleClick = () => {
  setOpen(!open);
}; 
  return(
    <HeaderWrapper>
    <Navbar open={open} handleClick={handleClick}  />
    <MenuButton open={open} handleClick={handleClick} />
    </HeaderWrapper>
  
    );
}
export default Movilnavbar;
