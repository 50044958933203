import React from 'react'
import {useDispatch , useSelector} from 'react-redux'
import RidersTable from './RidersTable'
import { obtenerRidersAccion , actualizarRidersAccion } from '../../../redux/adminRiders';

const Metricsriders = (props) => {
  const dispatch = useDispatch()
  const riders = useSelector(store => store.adminriders)
  //console.log(riders)
  React.useEffect(() => {
    const fetchData = () => {
      dispatch(obtenerRidersAccion())
    }
    fetchData()
        }, [])


React.useEffect(() => {
  //console.log(riders)
      }, [riders])

const handleUpdate = () =>{
  dispatch(actualizarRidersAccion())
}

  return (<div>
    <div>Gestion de Desempeño</div>
    <div>Bicci Riders</div>
    <div><button className="btn btn-bicci" onClick={handleUpdate}>Actualizar</button>
     {/* <button>Importar</button> <button>Exportar</button> */}
     </div>
    <span>Registros encontrados...</span>
    <div><RidersTable riders={riders} props={props}></RidersTable></div>
    
    </div>)
}

export default Metricsriders