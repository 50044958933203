import React from 'react'
import { useEffect } from "react";
import { useDispatch, useSelector} from 'react-redux';
import {db} from '../../../firebase';
import {  Grid, Tab } from 'semantic-ui-react';
import {
  ChartJsComponent,
  conditionalRegistration,
} from "react-chartjs3-wrapper";
import { ChartOptions, ChartData } from "chart.js";
import { Chart as ChartJS } from 'chart.js/auto'
import {
    Avatar,
    Box,
    Card,
    CardHeader,
    CardActions,
    CardContent,
    Divider,
    Typography
  } from '@mui/material';
import ActualizarDriverPerformance from './ActualizarDriverPerformance';
import ActualizarScheduleTimes from './ActualizarScheduleTimes';
import dataPerformants from "../../Web/Micuenta/dataPerformants.js";
import dataSchedule from '../../Web/Micuenta/dataSchedule.js';
import axios from 'axios'
require('dotenv').config()

const DetalleMetricsriders = (props) => {
    console.log(props.match.params.id)
    const dispatch = useDispatch()

    const userUid = props.match.params.id

    const [isRider, setIsRider] = React.useState(false)

    const[ data, setData]   = React.useState('')

    const[ dataDriverPerformance, setDataDriverPerformance]   = React.useState('')

    const[ dataScheduleTimes, setDataScheduleTimes]   = React.useState('')

    const [lastDataDriverPerformance, setLastDataDriverPerformance] = React.useState('')
    const [lastDataScheduleTimes, setLastDataScheduleTimes] = React.useState('')

    const [rankingPosition, setRankingPosition] = React.useState('')

    const [togle,setTogle] = React.useState(true)
    const handleSetTogle = () =>{
      setTogle(!togle)
    }

      useEffect(() => {
          obtenerDatosdeRider(userUid) 
          obtenerDriverPerformance(userUid)
          obtenerDriverScheduleTimes(userUid)
          getRidresRangking(userUid)
      }, [togle]);
      
      const obtenerDatosdeRider = async (userUid) => {
        try {
          const accountants = await db.collection("drivers")
              .doc(userUid)
              .get();
              var data = accountants.data();
              console.log(data)
               setData(data)
              } catch (error) {
              console.log('No es rider') 
            }
      } 

      const obtenerDriverPerformance = async (userUid) => {
        try {
            const accountantsPerformance = await db.collection("drivers")
                .doc(userUid)
                .collection('driverPerformance')
                .get()
                var data = accountantsPerformance.docs;
       //         console.log(data)
                setDataDriverPerformance(data)

                const metricsRider = data.map((doc) => ({
                  id: doc.id,
                  ...doc.data(),
                }));
                
                console.log(metricsRider)
                
                metricsRider.sort((a, b) => (a.LastPediodoAdd < b.LastPediodoAdd)?1:-1) ;
                var lastMetricsRider = metricsRider.slice(0, 4)
                console.log('AQUI deseméño')
                console.log(lastMetricsRider)
                setLastDataDriverPerformance(lastMetricsRider[0].data)



                } catch (error) {
        //        console.log(error) 
                console.log('No existe data accountantsPerformance') 
   
              }
      }

      const obtenerDriverScheduleTimes = async (userUid) => {
        try {
            const accountantsPerformance = await db.collection("drivers")
                .doc(userUid)
                .collection('scheduleTimes')
                .get()
                var data = accountantsPerformance.docs;
                console.log(data)
                setDataScheduleTimes(data)

                const metricsRiderTimes = data.map((doc) => ({
                  id: doc.id,
                  ...doc.data(),
                }));
                
                console.log(metricsRiderTimes)
                
                metricsRiderTimes.sort((a, b) => (a.LastPediodoAdd < b.LastPediodoAdd)?1:-1) ;
                var lastMetricsRider = metricsRiderTimes.slice(0, 4)
                console.log('AQUI Times')
                console.log(lastMetricsRider[0].data) 
                setLastDataScheduleTimes(lastMetricsRider[0].data)            



                } catch (error) {
                console.log('No existe data scheduleTimes') 
              }
      }

      const getRidresRangking = async(id) =>{
            try {
              const response = await axios.get(
                `${process.env.REACT_APP_BICCI_API_DEV}driverRankinga/${id}`,{  
                    headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_EBIEX_CLOUD_KEY}`,
                    'content-type':'text/html; charset=utf-8',
                  }
                })
                setRankingPosition('position')
                console.log(response.data)
                if(response.data.status === '404'){
                  setRankingPosition('No definido...')
                  
                  
                }
            } catch (error) {
              setRankingPosition('No definido...')
            }
      
      }


  return (
    <Grid>

      <Grid.Row> 
      <Grid.Column  computer={16} mobile={16} tablet={16}   className="dash-columns cetricas-riders-dash-columns"  >
        <div className="cetricas-riders-cards-title"><h3>Rider.</h3></div>
        <Card className="cetricas-riders-cards">
        <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            width: 'auto',
          }}
        >
          <Avatar
            src={data.avatar}
            sx={{
              height: 64,
              mb: 2,
              width: 64
            }}
          /> 
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {data.name}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {data.email}
          </Typography>
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        <div className='alignItemscenter'>{data.active===true?(<span className="activo">Activo</span>):(<span className="inactivo">Inactivo</span>)} </div>
      </CardActions>
        </Card> 
      </Grid.Column>
      <Grid.Column  computer={16} mobile={16} tablet={16}   className="dash-columns cetricas-riders-dash-columns"  >
        <div className="cetricas-riders-cards-title"><h3>Ranking</h3></div>
        <Card className="cetricas-riders-cards">
        <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            width: 'auto',
          }}
        >

          <Typography
            color="textPrimary"
            gutterBottom
            variant="h5"
          >
            {rankingPosition}
          </Typography>

        </Box>
      </CardContent>
      <Divider />

        </Card> 
      </Grid.Column>
      <Grid.Column  computer={16} mobile={16} tablet={16}   className="dash-columns cetricas-riders-dash-columns"  >
        <div className="cetricas-riders-cards-title"><h3>Desempeño</h3></div>
        <Card className="cetricas-riders-cards">
        <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            width: 'auto',
          }}
        >
     
     <ChartJsComponent 
              type="radar"
              data={dataPerformants(lastDataDriverPerformance).data}
              pointerEvents ="false"
            />
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
        
      </CardActions>
        </Card> 
      </Grid.Column>
      <Grid.Column  computer={16} mobile={16} tablet={16}   className="dash-columns cetricas-riders-dash-columns"  >
        <div className="cetricas-riders-cards-title"><h3>Servicio</h3></div>
        <Card className="cetricas-riders-cards">
        <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            width: 'auto',
          }}
        >
         
         <ChartJsComponent 
              type="radar"
              data={dataSchedule(lastDataScheduleTimes).data}
              pointerEvents ="false"
            />
        </Box>
      </CardContent>
      <Divider />
      <CardActions>
  
      </CardActions>
        </Card> 
      </Grid.Column>
      </Grid.Row>

   <div></div>
      
      <Grid.Row>
      <Grid.Column  computer={4} mobile={16} tablet={16} className="dash-columns cetricas-riders-dash-columns" >
        <Card className="cetricas-riders-cards">
        <CardContent>
        <h5>Evaluaciones Registradas</h5>
        <h5>Desempeño: </h5>
        {
          dataDriverPerformance.length>0?(<span>{dataDriverPerformance.length} registros</span>):(<span>0</span>)
        }
        <h5>Servicio</h5>
        {
          dataScheduleTimes.length>0?(<span>{dataScheduleTimes.length} registros</span>):(<span>0</span>)
        }
        </CardContent>
        <Divider />
        <CardActions>
        <button className="btn btn-bicci">Editar</button>
        </CardActions>
        </Card> 
      </Grid.Column>

      <Grid.Column  computer={6} mobile={16} tablet={16} className="dash-columns cetricas-riders-dash-columns" >
        <Card className="carddrivemetric">
        <CardContent>
        <h5>Registrar Desempeño</h5>
        <ActualizarDriverPerformance id={props.match.params.id} handleSetTogle={handleSetTogle} ></ActualizarDriverPerformance>
        </CardContent>
        <Divider />
        <CardActions>
     
        </CardActions>
        </Card> 
      </Grid.Column>

      <Grid.Column  computer={6} mobile={16} tablet={16} className="dash-columns cetricas-riders-dash-columns" >
        <Card className="carddrivemetric">
        <CardContent>
        <h5>Tiempos de Servicio</h5>
        <ActualizarScheduleTimes id={props.match.params.id} handleSetTogle={handleSetTogle}></ActualizarScheduleTimes>
        </CardContent>
        <Divider />
        <CardActions>
      
        </CardActions>
        </Card> 
      </Grid.Column>

      </Grid.Row>
    </Grid>
  )
}

export default DetalleMetricsriders