import { auth, db } from "../firebase";

// data inicial
const dataInicial = {
  loading: false,
  activo: false,
  itemid:"",
};

const LOADING_MOMENT = "LOADING_MOMENT";
const EDIT_USER_BICCI = "EDIT_USER_BICCI";
const EDIT_USER_ERROR = "EDIT_USER_ERROR";
const EDIT_USER_BICCI_EXITO = "EDIT_USER_BICCI_EXITO";
const FIND_INFORMATION_ERROR = "FIND_INFORMATION_ERROR";

export default function usuarioClienteBICCI(state = dataInicial, action) {
  switch (action.type) {
    case LOADING_MOMENT:
      return { ...state, loading: true };
    case EDIT_USER_BICCI:
      return { ...state, user: action.payload };
    case EDIT_USER_BICCI_EXITO:
      return { ...state };
    case EDIT_USER_ERROR:
      return { loading: false };
    case FIND_INFORMATION_ERROR:
      return { error: action.mjsstatus };
    default:
      return state;
  }
}
export const actualizarDatosdeCliente = (data) => async (dispatch) => {
  //  console.log(data.uid)
  dispatch({
    type: LOADING_MOMENT,
  });
  try {
    console.log(data.username);

    await db.collection("usuarios").doc(data.uid).update({
      username: data.username,
      userLastName: data.userLastName,
      userPhone: data.phone,
      userPreferenCont: data.userPreferenCont,
      userRut: data.userRut,
    });
    console.log("Datos Actualizados");
    //userType:data.userType,
    //     email:data.email,
    //     checked:data.checked,
  } catch (error) {
    console.log(error);
    dispatch({
      type: EDIT_USER_ERROR,
    });
  }
};

export const actualizarPassPorEnvioEmail = (data) => async (dispatch) => {
  //console.log(data.email)
  dispatch({
    type: LOADING_MOMENT,
  });
  try {
    console.log(data.email);

    await auth.sendPasswordResetEmail(data.email);
    console.log("Correo enviado...");
  } catch (error) {
    console.log(error);
    dispatch({
      type: EDIT_USER_ERROR,
    });
  }
};

export const crearPointADireccionByUser = (data) => async (dispatch) => {
  console.log("its Fine");
  try {
    const respon = await db
      .collection("usuarios")
      .doc(data.uid)
      .collection("direcciones")
      .doc()
      .set({
        companyPropietary: "",
        companyAsignament: "",
        propietary: data.uid,
        userName: data.userName,
        adressName: data.adressName,
        adressPhone: data.adressPhone,
        adressAddress: data.adressAddress,
        adressComent: data.adressComent,
        adressRegion: data.adressRegion,
        adressNumAddress: data.adressNumAddress,
        adressCity: data.adressCity,
        adressComuna: data.adressComuna,
        adressGeoNumAddressLat: data.adressGeoNumAddressLat,
        adressGeoNumAddressLng: data.adressGeoNumAddressLng,
        delete: false,
      });

    //  const messageRef = await db.collection('user').doc('idUsuarios').collection('direcciones').doc('nuevadireccion3').get()

    //  console.log(messageRef.data())
  } catch (error) {
    console.log(error);
  }
};

export const deletePointADireccionByUser = (data) => async (dispatch) => {
  console.log(data);
  try {
    await db
      .collection("usuarios")
      .doc(data.propietary)
      .collection("direcciones")
      .doc(data.id)
      .delete();
    console.log("it delete");
  } catch (error) {
    console.log(error);
  }
};

export const updatePointADireccionByUser = (data) => async (dispatch) => {
  console.log(data);
  console.log(data.uid);
  try {
    await db
      .collection("usuarios")
      .doc(data.uid)
      .collection("direcciones")
      .doc(data.documentDir)
      .update({
        companyPropietary: "",
        companyAsignament: "",
        propietary: data.uid,
        userName: data.userName,
        adressName: data.adressName,
        adressPhone: data.adressPhone,
        adressAddress: data.adressAddress,
        adressComent: data.adressComent,
        adressRegion: data.adressRegion,
        adressNumAddress: data.adressNumAddress,
        adressCity: data.adressCity,
        adressComuna: data.adressComuna,
        adressGeoNumAddressLat: data.adressGeoNumAddressLat,
        adressGeoNumAddressLng: data.adressGeoNumAddressLng,
        delete: false,
      });

    console.log("it UpDate");
  } catch (error) {
    console.log(error);
  }
};

