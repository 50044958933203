import React from 'react';
import {  Grid, Image, } from 'semantic-ui-react';
import { NavLink } from 'react-router-dom';
import TravelExploreIcon from '@mui/icons-material/TravelExplore';

const DevelopComponent = (props) => {
    console.log(props)
  return (
    <Grid celled='internally'>
     
    <Grid.Row>
      <Grid.Column computer={16} mobile={16} tablet={16}  id="Buscadorletf">
      <NavLink  to="/" exact>
      <Image  className="plaholderimg"  src='/mantenimiento.jpg' />
      </NavLink>
      <NavLink  to="/" exact>Regresar </NavLink>
      </Grid.Column>
    </Grid.Row>


  </Grid> 
  )
}

export default DevelopComponent