import React from 'react'
import {Link } from 'react-router-dom'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { useSelector} from 'react-redux'
import {Grid} from 'semantic-ui-react'
import {withRouter} from 'react-router-dom'
import Logo from  '../Logo/Logo' 

import Movilnavbar from '../Movilnavbar/Movilnavbar'

const Navbar = (props) => {

        const activo = useSelector(store => store.usuario.activo)
        React.useEffect(() => {
       
          
        }, [activo])
        return (
            <Grid className="navbarbici">
            <Grid.Row className="navbarconntainer" id="navbarconntainer">
            <Grid.Row className="navbarNaw">  
            
               <Grid.Column computer={4} mobile={8} tablet={4} className="navbar__left">
               <Link to="/" >  <Logo/> </Link>
               </Grid.Column>
               <Grid.Column computer={4} mobile={4} tablet={4} className="navbar__center">
               <div >
                   
                    <Movilnavbar props={props} />
                   
               </div>
               </Grid.Column>
               <Grid.Column computer={8} mobile={3} tablet={8} className="navbar____rigth">
                <div id='socialcontainer'>
                <Link  className="btn-bicci-prupura-color" to="#"><span>  <FacebookIcon></FacebookIcon></span></Link>
                <Link  className="btn-bicci-prupura-color" to="#"><span>  <InstagramIcon></InstagramIcon></span></Link>
                </div>

                
               </Grid.Column>

            </Grid.Row>
            </Grid.Row>
            </Grid>
    )
}

export default withRouter(Navbar)
