import FormLogin from "./FormLogin";
import React  from "react";
import {useDispatch, useSelector} from 'react-redux'
import { Image } from "semantic-ui-react";
import Logo from '../Header/Logo/Logo'

function formularioDeLoging(props){
  return(
    <FormLogin/>
  )
}

function LoginEmail(props){


  const dispatch = useDispatch()

  const loading = useSelector(store => store.usuario)
  const activo = useSelector(store => store.usuario.activo)


  React.useEffect(() => {
      if(activo===true){
          props.history.push('/mi-cuenta')
      }
      

  }, [activo])
    // const [isRegistrando, setIsRegistrando] = useState(false);
    // return(
    //   <div className="mt-5 text-center">
    //     <h2>{isRegistrando ? "Inicia sesión con email" : "Regístrate"  }</h2>
    //     <button className="btn btn-primary" onClick={() => setIsRegistrando(!isRegistrando)}>
    //       {isRegistrando ? "Quiero registrarme": "Ya tengo una cuenta" }
    //     </button>
    //       {
    //         isRegistrando ?  formularioDeLoging() : formularioDeRegistro()
    //       }
    //   </div>
    // )
return(
       <div className="mt-5 text-center formcontainer">
         <div id="brandsLogo">
         <Logo></Logo>
         </div>
         <h2>INICIAR SESIÓN</h2>
         
           {
           formularioDeLoging() 
           }
       </div>
)

  }

  export default LoginEmail