import React from 'react'
import { Form, Input } from "semantic-ui-react";
import {db} from '../../../firebase'
import moment from 'moment';
class ActualizarScheduleTimes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Ordenes:"",
            Distancia:"",
            TiempoHandOff:"",
            TiempoPreparacion:"",
            TiempoTrayecto:"",
            TiempoEntrega:"",
            Velocidad:"",
            TotalTime:"",
            createdAt:moment(Date.now()).format('DD MMMM YYYY, h:mm:ss a'),
            LastPediodoAdd:"",
            data:"",
            dataComplete:"",

        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeTimes = this.handleChangeTimes.bind(this)
        this.handleChangetime = this.handleChangetime.bind(this);
        this.handleTraductionCal = this.handleTraductionCal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadDataArray = this.loadDataArray.bind(this);
        this.handleClear = this.handleClear.bind(this)
        this.loadValidation = this.loadValidation.bind(this)


        }
        
        render() {
            return (
                <>
                  <h5>ActualizarScheduleTimes</h5>
                  <Form onSubmit={this.handleSubmit}>
                  <Form.Group widths="equal">
                    <Form.Field
                       className="inlineFlexObj"
                      control={Input}
                      type="date"
                      name="LastPediodoAdd"
                      onChange={this.handleChangetime}
                      value={this.state.LastPediodoAdd}
                    />  
                    </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      className="inlineFlexObj"
                      control={Input}
                      type="number"
                      name="Ordenes"
                      label ="Órdenes"
                      placeholder ="Numero de Ordenes"
                      onChange={this.handleChange}
                      value={this.state.Ordenes}
                    />  
                    </Form.Group>
                    <Form.Group widths="equal">
                    <Form.Field
                      className="inlineFlexObj"
                      control={Input}
                      type="number"
                      name="Distancia"
                      placeholder ="Distancia"
                      label ="Distancia promedio"
                      onChange={this.handleChange}
                      value={this.state.Distancia}
                    />  
                    </Form.Group>
                    <Form.Group widths="equal">
                    <Form.Field
                      className="inlineFlexObj"
                      control={Input}
                      type="number"
                      name="TiempoHandOff"
                      placeholder ="10 a 16 min"
                      label ="Tiempo en toma de pedido"
                      onChange={this.handleChangeTimes}
                      value={this.state.TiempoHandOff}
                    />  
                    </Form.Group>
                    <Form.Group widths="equal">
                    <Form.Field
                      className="inlineFlexObj"
                      control={Input}
                      type="number"
                      name="TiempoPreparacion"
                      label ="Tiempo de Preparacion"
                      placeholder ="1 a 16 min"
                      onChange={this.handleChangeTimes}
                      value={this.state.TiempoPreparacion}
                    />  
                    </Form.Group>
                    <Form.Group widths="equal">
                    <Form.Field
                      className="inlineFlexObj"
                      control={Input}
                      type="number"
                      name="TiempoTrayecto"
                      placeholder ="1 a 16 min"
                      label ="TiempoTrayecto"
                      onChange={this.handleChangeTimes}
                      value={this.state.TiempoTrayecto}
                    />  
                    </Form.Group>
                    <Form.Group widths="equal">
                    <Form.Field
                      className="inlineFlexObj"
                      control={Input}
                      type="number"
                      name="TiempoEntrega"
                      placeholder ="1 a 16 min"
                      label ="TiempoEntrega"
                      onChange={this.handleChangeTimes}
                      value={this.state.TiempoEntrega}
                    />  
                    </Form.Group>
                    <Form.Group widths="equal">
                    <Form.Field
                      className="inlineFlexObj"
                      control={Input}
                      type="number"
                      name="TotalTime"
                      label ="TotalTime"
                      placeholder ="10 a 16 min"
                      onChange={this.handleChangeTimes}
                      value={this.state.TotalTime}
                    />  
                    </Form.Group>
                    <Form.Group widths="equal">
                    <Form.Field
                      className="inlineFlexObj"
                      control={Input}
                      type="number"
                      name="Velocidad"
                      label ="Velocidad"
                      placeholder ="Km"
                      onChange={this.handleChange}
                      value={this.state.Velocidad}
                    />  
                    </Form.Group>
                    <button type="submit"className="btn btn-bicci">Agregar</button>  <br></br> <br></br>
                </Form>
                <div>
                      {
                      this.state.dataComplete?(<span className="alert alert-danger">{this.state.dataComplete}</span>):(<>{this.state.dataComplete}</>)
                      }</div>
                </>
  
            )}
            async handleChange(e){
                const target = e.target;
                const name = target.name;
                const value = target.value;
                if(value<0){this.setState({ [name]: 0 });}
                if(value>100){this.setState({ [name]: 100 });}
                if((value>=1)&&(value<=100)){this.setState({ [name]: value });}  
            }
            async handleChangeTimes(e){
                const target = e.target;
                const name = target.name;
                const value = target.value;
                if(value<0){this.setState({ [name]: 1 });}
                if(value>16){this.setState({ [name]: 16 });}
                if((value>=1)&&(value<=16)){this.setState({ [name]: value });}  
            }
            async handleChangeTimes16(e){
              const target = e.target;
              const name = target.name;
              const value = target.value;
              if(value<0){this.setState({ [name]: 1 });}
              if(value>16){this.setState({ [name]: 16 });}
              if((value>=1)&&(value<=16)){this.setState({ [name]: value });}  
          }

            async loadDataArray(){
                this.setState({ 'data':[
                    parseInt(this.state.TiempoHandOff),
                    parseInt(this.state.TiempoPreparacion),
                    parseInt(this.state.TiempoTrayecto),
                    parseInt(this.state.TiempoEntrega),
                    parseInt(this.state.TotalTime),
                 ]
                });
            }
            async handleChangetime(e){
                const target = e.target;
                const name = target.name;
                const value = moment(Date(target.value)).format('DD MMMM YYYY, h:mm:ss a');
                // console.log(name)
                // console.log(value)
                this.setState({ [name]: value });
            }

        async handleTraductionCal(e){
            const target = e.target;
            const name = target.name;
            const value = target.value;
            if(value<0){this.setState({ [name]: 1 });}
            if(value>16){this.setState({ [name]: 16 });}
            if((value>=1)&&(value<=16)){this.setState({ [name]: value });}             
           // var varRecal = this.state.TiempoEntrega
        }
        async handleSubmit(e){
            await this.loadDataArray()
            var sudmit = await this.loadValidation() 
            if(sudmit===false) {
          //    console.log('No es posible actualizar DriverPerformance')
            }else{
            try {
          //      console.log('update actualizarDriver times')
                await db.collection('drivers').doc(this.props.id).collection('scheduleTimes').doc().set(this.state)
                this.handleClear()
            } catch (error) {
           //     console.log(error)
            }
          }
        }

        async loadValidation(e){
          if(!this.state.Ordenes.trim()){this.setState({'dataComplete':'Por favor complete los datos Ordenes'});return false}
          if(!this.state.Distancia.trim()){this.setState({'dataComplete':'Por favor complete los datos Distancia'}); return false}
          if(!this.state.TiempoHandOff.trim()){this.setState({'dataComplete':'Por favor complete los datos TiempoHandOff '}); return false}
          if(!this.state.TiempoPreparacion.trim()){this.setState({'dataComplete':'Por favor complete los datos TiempoPreparacion'}); return false}
          if(!this.state.TiempoTrayecto.trim()){this.setState({'dataComplete':'Por favor complete los datos TiempoTrayecto'}); return false}
          if(!this.state.TiempoEntrega.trim()){this.setState({'dataComplete':'Por favor complete los  TiempoEntrega'}); return false}
          if(!this.state.Velocidad.trim()){this.setState({'dataComplete':'Por favor complete los  Velocidad'}); return false}
          if(!this.state.TotalTime.trim()){this.setState({'dataComplete':'Por favor complete los  TotalTime'}); return false}
          if(!this.state.LastPediodoAdd.trim()){this.setState({'dataComplete':'Por favor complete los datos LastPediodoAdd'}); return false}
          return true
        }


        async handleClear(e){
          this.setState({
            Ordenes:"",
            Distancia:"",
            TiempoHandOff:"",
            TiempoPreparacion:"",
            TiempoTrayecto:"",
            TiempoEntrega:"",
            Velocidad:"",
            TotalTime:"",
            createdAt:moment(Date.now()).format('DD MMMM YYYY, h:mm:ss a'),
            LastPediodoAdd:"",
            data:"",
            dataComplete:"",
        });
        this.props.handleSetTogle()
        }


            
}

export default ActualizarScheduleTimes;