import React from 'react'
import {Slideshow, Slide, TextoSlide} from './Slideshow'
import styled from 'styled-components';
import img1 from './img/1BR.jpg';
import img2 from './img/2BR.jpg';
import img3 from './img/3BR.jpg';

const Carouselhomeauto = () => {
  return (
    <main id="carusell">
    <Slideshow className="Slideshow" controles={true} autoplay={true} velocidad="3000" intervalo="1000">
      <Slide>
     
          <img src={img1} alt=""/>
        
        {/* <TextoSlide colorFondo="navy">
        <a href="https://linktr.ee/ebiex">  <h3> Navegación de contenidos </h3></a>
        </TextoSlide> */}
      </Slide>
      <Slide>
      
          <img src={img2} alt=""/>
      {/*     
        <TextoSlide>
        <a href="https://linktr.ee/ebiex"> <h3> Navegación de contenidos </h3></a>
        </TextoSlide> */}
      </Slide>
      <Slide>
      
      <img src={img3} alt=""/>
      {/*     
    <TextoSlide>
    <a href="https://linktr.ee/ebiex"> <h3> Navegación de contenidos </h3></a>
    </TextoSlide> */}
      </Slide>
    </Slideshow>
  </main>
  )
}

const Titulo = styled.p`
	font-size: 18px;
	font-weight: 700;
	text-transform: uppercase;
	margin-bottom: 10px;
`;

export default Carouselhomeauto