import firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/auth';
// import 'firebase/messaging';
import 'firebase/firestore';
require('dotenv').config()

firebase.initializeApp({
     apiKey: `${process.env.REACT_APP_RIDERS_API_KEY}`,
     authDomain: `${process.env.REACT_APP_RIDERS_AUTHDOMAIN}`,
     databaseURL: `${process.env.REACT_APP_RIDERS_DATABASEURL}`,
     projectId: `${process.env.REACT_APP_RIDERS_PROYECID}`,
     storageBucket: `${process.env.REACT_APP_RIDERS_STORAGEBUCKET}`,
     messagingSenderId: `${process.env.REACT_APP_RIDERS_MESSAGINGSENDERIR}`,
     appId: `${process.env.REACT_APP_RIDERS_APPID}`,
     measurementId: `${process.env.REACT_APP_RIDERS_MEASUREMENTID}`
})
const storage = firebase.storage() 
const auth = firebase.auth();
// const messaging = firebase.messaging();
const db = firebase.firestore();


export {auth, db, firebase, storage}