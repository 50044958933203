function getdata(props){
const data = {
  labels: [
    "Total time",
    "Toma de Orden",
    "Preparacion",
    "Trayecto",
    "Tiempo de entrega",
  ],
  datasets: [
    {
      label: "Tiempo mínimo    ",
      data: [0, 0, 0, 0, 0],
  
      
      backgroundColor: "rgba(17, 220, 25, 0.3)",
      borderColor: "rgb(17, 220, 25)",
      pointBackgroundColor: "rgb(17, 220, 25)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgb(255, 99, 132)"

    },

    {
      label: "Tiempo máximo     ",
      data: [25, 25, 25, 25, 25],
    
      backgroundColor: "rgba(184, 221, 242, 0.1)",
      borderColor: "rgba(184, 221, 242, 0.0)",
      pointBackgroundColor: "rgba(184, 221, 242, 0.1)",
      pointBorderColor: "#f54278 ",
      pointHoverBackgroundColor: "#00FFFFFF ",
      pointHoverBorderColor: "rgba(184, 221, 242, 0.1)"
    },
    {
      label: "Tiempos de entregas",
      data: props,
      fill: true,

  //    backgroundColor: "rgba(17, 220, 25, 0.3)",
      borderColor: "rgb(17, 220, 25)",
      pointBackgroundColor: "rgb(17, 220, 25)",
      pointBorderColor: "#fff",
      pointHoverBackgroundColor: "#fff",
      pointHoverBorderColor: "rgb(255, 99, 132)"
    },

  ]
};
return data
}

const dataSchedule =(props)=> {
   //console.log(props)
  var obj = {
  type: 'radar',
  data: getdata(props),
   
  options: {
    scales: {
      y: {
        beginAtZero: true
      }
    }
  },
  }
  return obj
};

export default dataSchedule;