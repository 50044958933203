import React from 'react';
import ReactDOM from 'react-dom';
import './scss/global.scss'
import App from './App.jsx';

import {Provider} from 'react-redux'
import generateStore from './redux/store'

const store = generateStore()

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(
   <React.StrictMode>
   
    <Provider store={store}>
      <App />
    </Provider>
 
  </React.StrictMode>, rootElement);
} else {
  ReactDOM.render( 
  <React.StrictMode>
   
    <Provider store={store}>
      <App />
    </Provider>
 
  </React.StrictMode>, rootElement);
}

