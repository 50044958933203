import React from 'react'
import { Container, Input, Form } from 'semantic-ui-react'
import { Box, Button } from '@mui/material';
import {useDispatch} from 'react-redux';
import { actualizarPassPorEnvioEmail }  from "../../redux/acccionesClientes";

const data={
    email:""
  }
const Rcontraseña = (props) => {
    const dispatch = useDispatch()
    const [userPassReset,setUserPassReset] = React.useState('')
    const[success,setSuccess] = React.useState(null)  
    const[error,setError] = React.useState(null)
  
    const actualizarPassPorEmail = e =>{
      e.preventDefault()
  
      if(!userPassReset.trim()){
        console.log('Ingrese Email')
        setError('Ingrese Email')
        return
      }
      data.email = userPassReset
  
      setSuccess('El mensaje fue enviado a tu correo electrónico...')
      dispatch(actualizarPassPorEnvioEmail(data))
      function delay() {
       
        setSuccess(null)
        props.history.push("/login")

      }
      setTimeout(delay, 1000);
      
  }
  
    return (
      <Container className='bodyFroms' id="bodyFromsRS">
      <div className="mt-5 text-center">
      <Container className='formcontent ' id="ajustefromIni">
            <h5>  Crear o recuperar contraseña </h5>
              {
                              error ? (
                                  <div className="alert alert-danger">
                                  {error}
                              </div>
                              ):null
                          }
             <Form onSubmit={actualizarPassPorEmail}>
      
         
                          {
                              success ? (
                                  <div className="alert alert-success">
                                  {success}
                              </div>
                              ):null
                          }
             <Form.Group widths='equal' >
              <Form.Field
                className='field-lable-email'
                control={Input}
                type = "email"
                icon = 'at' 
                iconPosition='left'
                label='Recibirás un correo electrónico para establecer una nueva contraseña'
                placeholder='email@dominio.com'
                value={userPassReset}
                onChange = {e => setUserPassReset(e.target.value)}
  
         
              />
              </Form.Group>
              <Box
                sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
                }}
            >
            <Button
            className="btn-bicci btn-bicci-prupura"
              color="primary"
              variant="contained"
              type="sutmit"
             
            >
              Enviar email
            </Button>
          </Box>
          </Form>
      </Container> 
      </div>
          
      </Container> 
    )
}

export default Rcontraseña