

function getdata(props){

 // console.log(props)

const data = {
    labels: [
      "Total time",
      "Cumplimiento",
      "Vestimenta",
      "Nivel de servicio",
      "puntualidad",
      "Ordenes",
    ],
    datasets: [
      {
        label: "Puntuación mínima",
        data: [0, 0, 0, 0, 0, 0],
        backgroundColor: "rgba(250, 12, 127, 0.1)",
        borderColor: "rgba(250, 12, 127, 0.1)",
        pointBackgroundColor: "rgba(250, 12, 127, 0.1)",
        pointBorderColor: "#00FFFFFF",
        pointHoverBackgroundColor: "#00FFFFFF",
        pointHoverBorderColor: "rgba(250, 12, 127, 0.1)"
      },
      {
        label: "Puntuación máxima",
        data: [100, 100, 100, 100, 100, 100],
        backgroundColor: "rgba(184, 221, 242, 0.1)",
        borderColor: "rgba(184, 221, 242, 0.0)",
        pointBackgroundColor: "rgba(184, 221, 242, 0.1)",
        pointBorderColor: "#00FFFFFF ",
        pointHoverBackgroundColor: "#00FFFFFF ",
        pointHoverBorderColor: "rgba(184, 221, 242, 0.1)"
      },
      {
        label: "Tu desempeñó       ",
        data: props,
        backgroundColor: "rgba(17, 220, 25, 0.3)",
        borderColor: "rgb(17, 220, 25)",
        pointBackgroundColor: "rgb(17, 220, 25)",
        pointBorderColor: "#fff",
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgb(255, 99, 132)"
      },
      

    ]
  };
  return data
}
  const driverPerformance =(props)=> {
// console.log(props)
    var obj = {
    data: getdata(props),
    id:'canvasId',
    
    width:450,
    options: {
      responsive: true,
      scale: {
        ticks: {
          max: 100,
          min: 0,
          stepSize: 1
        }
      }
    }
    }
    return obj
  };
  
  export default driverPerformance;