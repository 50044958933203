import React  from 'react';
import styled from "styled-components";
import { NavLink } from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux'
import { cerrarSesionAccion } from '../../../redux/usuarioDucks';
const NavbarWrapper = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #f9f9f9;
  position: fixed;
  top: 8vh;
  right: ${props => (props.open ? "0" : "-100%")};
  width: 100%;
  height: 90vh;
  transition: right 0.3s linear;
  z-index: 20;
  .navlinkbar{
    border-bottom: 1px solid rgba(34,36,38,.15);
    width: 90%;
  }

  @media only screen and (min-width: 780px) {
    flex-direction: row;
    position: initial;
    height: auto;
    justify-content: flex-end;
    z-index: 20;
    background: transparent;
    :hover{
      color: rgb(0, 205, 246)!important; 
    }
    :active{
      color: rgb(0, 205, 246)!important; 
    }
    .navlinkbar{
      border:none;
      width:auto;

    }
    
  }

  a {
    padding: 0.5rem 0.8rem;
    color: ##232426!important;
    text-decoration: none;
    font-weight: 500;
    :hover{
      color: rgb(0, 205, 246)!important; 
    }
    :focus{
      color: rgb(0, 205, 246)!important; 
    }
    :active{
      color: rgb(0, 205, 246)!important; 
    }
    .navlinkbar{
      border-top: 1px solid rgba(34,36,38,.15);
    }
  }
`;

function Navbar ({props, open, handleClick}) {
  const dispatch = useDispatch()
  const activo = useSelector(store => store.usuario.activo)

  const cerrarSesion = (props) => {
    console.log(props)
  //    props.history.push('/')
      dispatch(cerrarSesionAccion())
     
  }
   return (
    <NavbarWrapper open={open}>
    <div  className="navlinkbar">
    <a className="btn" href="https://bicci.cl/"  >Home</a>
    </div>
    <div  className="navlinkbar">
    <NavLink className="btn " onClick={handleClick} to="/bicci-Riders" exact>Bicci-Riders</NavLink>
    </div>
    <div  className="navlinkbar">
    <NavLink className="btn " onClick={handleClick} to="/mi-cuenta" exact>Mi cuenta</NavLink>
    </div>
    {/* <div  className="navlinkbar">
    <a className="btn stylenavlinkbar" href="https://timify.com/profile/biccirider/?v=4" > Agenda </a>
    </div> */}
    <div  className="navlinkbar">
    <NavLink className="btn " onClick={handleClick}  to="/equipamiento" exact>Equipamiento</NavLink> 
    </div>
    <div  className="navlinkbar">
    <NavLink className="btn " onClick={handleClick} to="/contacto" exact>Contacto</NavLink> 
    </div>
    {
      activo?(    <div  className="navlinkbar">
      <NavLink className="btn mr-2 " onClick={() => cerrarSesion(props)} to="/login" ><i className="sign out alternate icon koFoOQ"></i>Salir</NavLink>
      </div>):(   <div  className="navlinkbar">
    <NavLink className="btn " to="/login" exact>Login</NavLink>
    </div>)
    }
   
    </NavbarWrapper>
  );
};

export default Navbar;
