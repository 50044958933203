import React from 'react'

const ConsultaOnline = () => {
  return (
      <div>
           <span className="alert alert-success">Hola, escanea el QR del carnet del Bicci Rider para obtener más información</span>
        
      </div>
    
  )
}

export default ConsultaOnline