import React from 'react'
import { Form, Input } from "semantic-ui-react";
import {db} from '../../../firebase'
import moment from 'moment';


class ActualizarDriverPerformance extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            TotalTime:"",
            Cumplimiento:"",
            Vestimenta:"",
            Niveldeservicio:"",
            Puntualidad:"",
            Ordenes:"",
            createdAt:moment(Date.now()).format('DD MMMM YYYY, h:mm:ss a'),
            LastPediodoAdd:"",
            data:"",
            dataComplete:""
            
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangetime = this.handleChangetime.bind(this);
        this.handleTraductionCal = this.handleTraductionCal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.loadDataArray = this.loadDataArray.bind(this);
        this.handleClear = this.handleClear.bind(this)
        this.loadValidation = this.loadValidation.bind(this)
        }
            
        render() {
            return (
                <>
                <h5>ActualizarDriverPerformance</h5>
                <Form onSubmit={this.handleSubmit}>
                <Form.Group widths="equal">
                    <Form.Field
                      control={Input}
                      type="date"
                      name="LastPediodoAdd"
                      onChange={this.handleChangetime}
                      value={this.state.LastPediodoAdd}
                    />  
                    </Form.Group>
                  <Form.Group widths="equal">
                    <Form.Field
                      className="inlineFlexObj"
                      control={Input}
                      type="number"
                      label ="Tiempo total"
                      name="TotalTime"
                      placeholder ="1 a 100%"
                      onChange={this.handleChange}
                      value={this.state.TotalTime}
                    />  
                    </Form.Group>
                    <Form.Group widths="equal">
                    <Form.Field
                      className="inlineFlexObj"
                      control={Input}
                      type="number"
                      name="Cumplimiento"
                      placeholder ="10 a 100%"
                      label ="Cumplimiento"
                      onChange={this.handleChange}
                      value={this.state.Cumplimiento}
                    />  
                    </Form.Group>
                    <Form.Group widths="equal">
                    <Form.Field
                      className="inlineFlexObj"
                      control={Input}
                      type="number"
                      name="Vestimenta"
                      label ="Vestimenta"
                      placeholder ="1 a 100%"
                      onChange={this.handleChange}
                      value={this.state.Vestimenta}
                    />  
                    </Form.Group>
                    <Form.Group widths="equal">
                    <Form.Field
                      className="inlineFlexObj"
                      control={Input}
                      type="number"
                      name="Niveldeservicio"
                      label="Nivel de servicio"
                      placeholder ="1 a 100%"
                      onChange={this.handleChange}
                      value={this.state.Niveldeservicio}
                    />  
                    </Form.Group>
                    <Form.Group widths="equal">
                    <Form.Field
                      className="inlineFlexObj"
                      control={Input}
                      type="number"
                      name="Puntualidad"
                      label ="Puntualidad"
                      placeholder ="1 a 100%"
                      onChange={this.handleChange}
                      value={this.state.Puntualidad}
                    />  
                    </Form.Group>
                    <Form.Group widths="equal">
                    <Form.Field
                      className="inlineFlexObj"
                      control={Input}
                      type="number"
                      name="Ordenes"
                      label ="Ordenes"
                      placeholder ="1 a 100"
                      onChange={this.handleChange}
                      value={this.state.Ordenes}
                    />  
                    </Form.Group>
                      <button type="submit"className="btn btn-bicci">Agregar</button>       <br></br> <br></br>
               
               
                </Form>
                <div>
                      {
                      this.state.dataComplete?(<span className="alert alert-danger">{this.state.dataComplete}</span>):(<>{this.state.dataComplete}</>)
                      }</div>

                </>
            )}

        async handleChange(e){
            const target = e.target;
            const name = target.name;
            const value = target.value;
            if(value<0){this.setState({ [name]: 0 });}
            if(value>100){this.setState({ [name]: 100 });}
            if((value>=1)&&(value<=100)){this.setState({ [name]: value });}  
       
        }


        async loadDataArray(){
            this.setState({ 'data':[
             parseInt(this.state.TotalTime),
             parseInt(this.state.Cumplimiento),
             parseInt(this.state.Vestimenta),
             parseInt(this.state.Niveldeservicio),
             parseInt(this.state.Puntualidad),
             parseInt(this.state.Ordenes),
             ]
            });
        }

        async handleChangetime(e){
            const target = e.target;
            const name = target.name;
            const value = moment(Date(target.value)).format('DD MMMM YYYY, h:mm:ss a');
            this.setState({ [name]: value });
           // console.log(this.state)
        }

        async handleTraductionCal(e){

        }
        async handleSubmit(e){
          await this.loadDataArray()
          var sudmit = await this.loadValidation() 
          if(sudmit===false) {
           // console.log('No es posible actualizar DriverPerformance')
          }else{
            try { 
           //   console.log('update actualizarDriverPerformance')
              await db.collection('drivers').doc(this.props.id).collection('driverPerformance').doc().set(this.state)
              this.handleClear()
          } catch (error) {
            //  console.log(error)
          }
            
          }
     
        }

        async loadValidation(e){
          if(!this.state.TotalTime.trim()){this.setState({'dataComplete':'Por favor complete los datos TotalTime'});return false}
          if(!this.state.Cumplimiento.trim()){this.setState({'dataComplete':'Por favor complete los datos Cumplimiento'}); return false}
          if(!this.state.Vestimenta.trim()){this.setState({'dataComplete':'Por favor complete los datos Vestimenta '}); return false}
          if(!this.state.Niveldeservicio.trim()){this.setState({'dataComplete':'Por favor complete los datos Puntualidad'}); return false}
          if(!this.state.Puntualidad.trim()){this.setState({'dataComplete':'Por favor complete los datos Puntualidad'}); return false}
          if(!this.state.Ordenes.trim()){this.setState({'dataComplete':'Por favor complete los  Ordenes'}); return false}
          if(!this.state.LastPediodoAdd.trim()){this.setState({'dataComplete':'Por favor complete los datos LastPediodoAdd'}); return false}
          return true
        }


        async handleClear(e){
          this.setState({
            TotalTime:"",
            Cumplimiento:"",
            Vestimenta:"",
            Niveldeservicio:"",
            Puntualidad:"",
            Ordenes:"",
            createdAt:moment(Date.now()).format('DD MMMM YYYY, h:mm:ss a'),
            LastPediodoAdd:"",
            data:"",
            dataComplete:""
        });
        this.props.handleSetTogle()
        }
}

export default ActualizarDriverPerformance;