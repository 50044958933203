import React from 'react'
import {  Grid, Image,  } from 'semantic-ui-react';
import {
  Box,
  Card,
} from '@mui/material';
import ReactPlayer from 'react-player'
import Carouselhomeauto from '../../../components/Carouselhome/Carouselhomeauto';
import { NavLink } from 'react-router-dom';
import imgh001 from './h001.png';
import imgh002 from './h002.png';
import imgh003 from './h003.png';
import imgh004 from './h004.png';
//const userUid= "#"
const Bicciriders = () => {
  return (
    <div className='bodyriderhome'>
  <Carouselhomeauto></Carouselhomeauto>
  <div className="titleh2"><h2>¿Qué te ofrecemos?</h2></div>

    <Grid celled='internally'>
     
    <Grid.Row className="flip-cards-containers">
      <Grid.Column computer={4} mobile={16} tablet={8}  >
      <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <img src={imgh001} alt="Avatar" className="avatar" ></img>
          <h2>INGRESOS</h2>
          <span></span>
          <div className="elementor-flip-box__layer__description">
Todos los ingresos son transparentes. Tanto el <strong>despacho </strong>como la <strong>propina </strong>son 100% para ti.
           </div>
        </div>
        <div className="flip-card-back">
        <div className="elementor-flip-box__layer__description">
Así es! Ingresos transparentes. El despacho y la propina 100% tuyos.<br/>
<strong> Sin comisiones ni cobros extras.</strong>						</div>
        </div>
      </div>
    </div>
      </Grid.Column>
      <Grid.Column computer={4} mobile={16} tablet={8}  >
      <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <img src={imgh002} alt="Avatar" className="avatar" ></img>
          <h2>FUNDACIÓN</h2>
          <span></span>
          <div className="elementor-flip-box__layer__description">
Por medio de la fundación bicci podrás optar a beneficios sociales para ti y tu familia.
           </div>
        </div>
        <div className="flip-card-back">
        <div className="elementor-flip-box__layer__description">
“En bicci creemos que todos juntos y de forma colectiva podemos colaborar con una sociedad más justa y solidaria”.<br/> Tenemos como propósito transformar la sociedad en la que vivimos en un espacio de colaboración, unidad y respeto entre todos. Súbete a la red de bicci riders.				
		</div>
        </div>
      </div>
    </div>
      </Grid.Column>
      <Grid.Column computer={4} mobile={16} tablet={8}  >
      <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <img src={imgh003} alt="Avatar" className="avatar" ></img>
          <h2>BENEFICIOS</h2>
          <span></span>
          <div className="elementor-flip-box__layer__description">
enemos beneficios especialmente pansados para ti.
           </div>
        </div>
        <div className="flip-card-back">
        <div className="elementor-flip-box__layer__description">
Conocemos las necesidades que tienes y hemos desarrollado alianzas y beneficios en salud, financiero y muchos más.						</div>
        </div>
      </div>
    </div>
      </Grid.Column>
      <Grid.Column computer={4} mobile={16} tablet={8}  >
      <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <img src={imgh004} alt="Avatar" className="avatar" ></img>
          <h2>FUNDACIÓN</h2>
          <span></span>
          <div className="elementor-flip-box__layer__description">
Quemos fomentar la electromovilidad. si no tienes vehículo nosotros te daremos facilidades para que obtengas tu propia eco-bicci. 
           </div>
        </div>
        <div className="flip-card-back">
        <div className="elementor-flip-box__layer__description">
Parte importante de nuestros valores es cuidar el medio ambiente, por lo tanto si lo deseas puedes optar a facilidades especiales para contar con tu propia BICCI eléctrica.						</div>
        </div>
      </div>
    </div>
      </Grid.Column>
    </Grid.Row>
    


      <div className="titleh2 color-gree"><h2>Por un mejor futuro !</h2></div>

    <Grid.Row className="videocontainer">
   
     <ReactPlayer
         // url='https://youtu.be/0QYT6bIQqgw'
          url='https://www.youtube-nocookie.com/embed/0QYT6bIQqgw'
          
          className='react-player'
         
          controls
          width='70VW'
          height='650px'
         
          config={{ youtube: { playerVars: {  origin:'http://localhost:8100'} } } }
        /> 
{/*       
        <iframe id="iframeYoutube" src="https://www.youtube-nocookie.com/embed/0QYT6bIQqgw" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" ></iframe>
    */}
    </Grid.Row>

    <Grid.Row className='justifycontent'>
    <Grid.Column computer={4} mobile={16} tablet={6} id="paragraph2">
    <Card  className="card-type" >
           <Box sx={{ minWidth: 350, minHeight:450 }} id="card-type01">
          <h5>Fundación Bicci</h5>
          <span>Te invitamos a ser parte del programa de ayuda social dirigido a ti y tu familia.</span>
          <span>Informate <NavLink to='#' >aqui</NavLink></span>
          <br></br>
          <Image  className="plaholderimg"  src='https://bicci.cl/wp-content/uploads/2020/08/Banner-768-x-511.jpg' />
   
            </Box>
    </Card>
    </Grid.Column>

    <Grid.Column computer={4} mobile={16} tablet={6}>
    <Card  className="card-type">
           <Box sx={{ minWidth: 350 }} id="card-type02">
            <Image  className="plaholderimg"  src='https://bicci.cl/wp-content/uploads/2020/08/Bicicleta-01-01-copia-copia-768x593.jpg' />
           <h5>¡Súbete a tu propia Eco Bicci!</h5>
           <ul>
             <li>Reduce 50% el tiempo de desplazamiento en comparación con bicicleta normal.</li>
           <li>Te damos facilidades para obtener tu propia <span>BICCI eléctrica.</span></li>
           </ul>
            </Box>
          </Card>
      </Grid.Column>

    </Grid.Row>

    <Grid.Row>
      <Grid.Column computer={16} mobile={16} tablet={16} >
      <Card  className="card-type2">
      <Box sx={{ maxWidth: 1200,  }}>
         <NavLink to="#" exact><button className="btn-bicci btn-globalhover-calipso" > Registra pago </button></NavLink>
         <NavLink to="/login" exact><button className="btn-bicci btn-globalhover-calipso"> Aceso Bicci Riders</button>  </NavLink>
         {/* <a href="https://timify.com/profile/biccirider/?v=4" ><button className="btn-bicci btn-globalhover-calipso"> Agenda Aqui</button> </a> */}
      </Box>
      </Card>

      </Grid.Column>

    </Grid.Row>

  </Grid> 
  </div>
  )
}

export default Bicciriders