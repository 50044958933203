import React, { useState } from 'react'
import { useEffect } from "react";
import {db} from '../../firebase';
import QRCode from "react-qr-code";
import {
    Avatar,
    Box,
    Card,
    CardActions,
    CardContent,
    Divider,
  } from '@mui/material';
 var isFine ;
 var userUid = "noregister"
 var userState = false


const Consultaonlineid = (props) => {



console.log(props)
console.log(props.match.params.id)

userUid=props.match.params.id
//props.history.push(`/consulta/${props.match.params.id}`)


//const [isFine, setIsFine ] =  useState(false)
const [isFind, setIsFind] = React.useState("");
const [dataRider,setDatarider] = useState('')




React.useEffect(() => {
  const rideruid = props.match.params.id
      obtenerDatosdRider(rideruid) //'LVY9zIL1OAbep6OvrrjEovLsKRD2' 
       props.expamadmin(true);
},[]);



const obtenerDatosdRider = async (userUid) => {
  console.log(userUid)
  try {
    const accountants = await db.collection("drivers")
        .doc(userUid)
        .get();
        var data = accountants.data();
        //console.log(data)
        if(data !== undefined){
          setDatarider(data)
          console.log(data)
          setIsFind(true)}        
          
      } catch (error) {
          console.log(error)
          setIsFind(false)
      }
      } 

    

  
  return (
  <div id='RiderContent'>
    
    {
   isFind===true?(
    <div>
    <Card >
    <CardContent>
            <Box
              sx={{
                alignItems: 'center',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
           <Card className="carnetRider" >
                 <CardContent>
                      <Box
                        sx={{
                          alignItems: 'center',
                          display: 'flex',
                          flexDirection: 'column'
                        }}
                      >
                 <Avatar
                    src={dataRider.avatar}
                    sx={{
                      height: 64,
                      mb: 2,
                      width: 64
                    }} />

                      <strong>{dataRider.name}</strong>
                      <strong>{dataRider.dni}</strong>
                      <br></br>       
                      <div className='alignItemscenter'>{dataRider.active===true?(<span className="activo">Activo</span>):(<span className="inactivo">Inactivo</span>)} </div>
              </Box>
            </CardContent>
            <Divider />
            <div className="centercontent">
            <strong>ID: {dataRider.bicciNumber}</strong>
            </div>
            <CardActions > 
              <div className='centercontent'>
              <div className='borderCode'>
                      <QRCode value={`https://get-bicci.web.app/consulta/${dataRider.id}`} size={150} level='H' fgColor='#741ab0'/>
                      </div>
              </div>  
            </CardActions>
            <div className="centercontent contac-data">
            Empresa: Bicci Delivery
            <br></br>
            CONTACTO <br></br>
            +56 9 4595 0049
            <br></br>
            </div>
            </Card>
    </Box>
  </CardContent>
  <Divider />
  <CardActions>
  </CardActions>
   </Card>
</div>

   ):(<div>


    <Card >
      <CardContent>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
                <Card className="carnetRiderNofont" >
                <Divider />
                <div className="centercontent">
                </div>
                <div className="centercontent contac-data-nofound">
                <strong>No se encuentra usuario</strong>
                <br></br>
                Numero Soporte <br></br>
                +56 9 4595 0049
                <br></br>
                </div>
                </Card>

        </Box>
      </CardContent>
      <Divider />
      <CardActions>
    
      </CardActions>

      </Card>
      
   </div>)

   } 


  </div>
  )
}

export default Consultaonlineid