import React from 'react';
import DevelopComponent from './components/DevelopComponent'
import Page404 from './Pages/Page404'
import LoginEmail from './components/LoginEmail/LoginEmail';
import Navbar from './components/Header/Navbar/Navbar';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import {auth} from './firebase'
import Footer from './components/Footer';
import { cerrarSesionAccion } from './redux/usuarioDucks';
// import Contacto from './Pages/Web/Contacto/Contacto';
// import Equipamiento from './Pages/Web/Equipamiento/Equipamiento';
// import Agenda from './Pages/Web/Agenda/Agenda';
import Bicciriders from './Pages/Web/Bicciriders/Bicciriders';
import Micuenta from './Pages/Web/Micuenta';
import { Container } from "semantic-ui-react";
import Rcontraseña from './components/LoginEmail/Rcontraseña';
// import Paymentmanagement from './Pages/Adminriders/Paymentmanagement/Paymentmanagement';
import Metricsriders from './Pages/Adminriders/Metricsriders/Metricsriders';
import Consultaonlineid from './components/Consultaonlineid/Consultaonlineid';
import Consultaonline from './components/Consultaonline/Consultaonline';
import Adminriders from './Pages/Adminriders/Adminriders';
import Managementriders from './Pages/Adminriders/Managementriders/Managementriders';
import DetalleMetricsriders from './Pages/Adminriders/Metricsriders/DetalleMetricsriders';
import {useDispatch } from 'react-redux'

require('dotenv').config()
function App() {
  const dispatch = useDispatch()
  const [landScrenMenu, setLandScrenMenu] = React.useState(false);
  const [firebaseUser, setFirebaseUser] = React.useState(false)

  React.useEffect(() => {
    const fetchUser = () => {
      auth.onAuthStateChanged(user => {

        //console.log(user)
          if(user){
              setFirebaseUser(user)
          }else{
              setFirebaseUser('invitado')
             
          }
        //console.log(firebaseUser)

      })
    }
    fetchUser()
  }, [firebaseUser])

  const expamAdmin = () => {
    //console.log('expam Admin')
    setLandScrenMenu(!landScrenMenu);
  };

  const RutaPrivada = ({component, path, ...rest}) => {
     if(localStorage.getItem('usuarioBicci')){
       const usuarioStorage = JSON.parse(localStorage.getItem('usuarioBicci'))
            try {
               // console.log(usuarioStorage.uid)
               // console.log(firebaseUser.uid)
                } catch (error) {
                  console.log(`Error: ${error}`) // ReferenceError: p is not defined 
                  dispatch(cerrarSesionAccion())
                  return <Redirect to="/" {...rest} />

            }



       if(usuarioStorage.uid === firebaseUser.uid){
             return <Route component={component} path={path} user={firebaseUser.uid} {...rest} />
        }else{
      //console.log('redirecciona a login')
         return <Redirect to="/login" {...rest} />
       }
      //}
     }else{
    //console.log('redirecciona a login')
       return <Redirect to="/login" {...rest} />
     }
  }

  const RutaProtegida = ({component, path, ...rest}) => {
        if(localStorage.getItem('usuarioBicci')){
          const usuarioStorage = JSON.parse(localStorage.getItem('usuarioBicci'))
        //console.log('Ruta Protegida')
        if(usuarioStorage.uid === firebaseUser.uid){
              if(process.env.REACT_APP_RIDERS_ADMIN.indexOf(usuarioStorage.roll)){
                return <Route component={component} path={path} {...rest} />
              }else{ 
              return <Redirect to="/404" component={Page404}  />
            }
          }else{
            //console.log('redirecciona a login')
            return <Redirect to="/login" {...rest} />
          }
        //}
        }else{
        //console.log('redirecciona a login')
          return <Redirect to="/login" {...rest} />
        }
    }

  return (
    <div>

    {
          firebaseUser === false ?  (<div>Cargando...</div>):(<>     
          <Router>
              <Container
              fluid
              className={
                landScrenMenu === true
                  ? "landScreend-topcontainer"
                  : "topbar topcontainer"
              }
            >
            <Navbar />
            </Container>
              <div className="container mt-3 containerbody">
                <Switch>
             
                  <Route component={Bicciriders} path="/" exact/>
                  <Route component={Bicciriders} path="/bicci-riders" exact/>
                  <Route component={Bicciriders} path="/riders" exact/>
                  {/* <Route component={DevelopComponent} path="/agenda" exact/> */}
                  <Route component={DevelopComponent} path="/equipamiento" exact/>
                  <Route component={DevelopComponent} path="/contacto" exact/>
        
                  <Route component={Consultaonline} path="/consulta" exact/>
                  <Route
                      path="/consulta/:id"
                      render={(props) => <Consultaonlineid {...props} expamadmin={setLandScrenMenu} />}
                    />
                  
                  <Route component={LoginEmail} path="/login" exact/>
                  <Route component={Rcontraseña} path="/resset-pass"></Route>
                  <RutaPrivada component={Micuenta} path="/mi-cuenta" exact/>

                  <RutaProtegida component={Adminriders} path="/admin"  exact/>

                  <Adminriders component={Managementriders} path="/admin/Gestion-riders" exact/>
                  <Adminriders component={Metricsriders} path="/admin/Metricas-riders" exact/>
                  <Adminriders component={DetalleMetricsriders} path="/admin/Metricas-riders/:id" exact/>
                  <Adminriders component={Managementriders} path="/admin/finanzas-riders" exact/>
                  <Route path="*" component={Page404} />
                  
                </Switch>
              </div>
            <Footer/>
            </Router></>)
    }
    


    </div>
    



















  )
}

export default App;
