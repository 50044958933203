import React from 'react'
import { NavLink } from 'react-router-dom';
import {
  Form,
  Input,
  Container,} from 'semantic-ui-react'
import { ingresoUsuarioLogin } from '../../redux/usuarioDucks'
import {useDispatch, useSelector} from 'react-redux'
import BtnAceceder from './BtnAceceder';

// import BtnRegistrarme from '../Login/buttons/BtnRegistrarme';
  const FormLogin = (props) => {
  const dispatch = useDispatch()
  const resperror = useSelector(store => store.usuario.error)

  const[email,setEmail]= React.useState('')
  const[pass,setPass]= React.useState('')

  const[error,setError] = React.useState(null)
  
  const[success,setSuccess] = React.useState(null)
 
  const procesarDatos = e =>{
      e.preventDefault()
      if(!email.trim()){
      //    console.log('Ingrese Email')
          setError('Ingrese Email')
          return
      }
      if(!pass.trim()){
      //    console.log('Ingrese Contraseña')
          setError('Ingrese Contraseña')
          return
      }
      if(pass.length < 5){
     //     console.log('Contraseña mayor a 6 caracteres')
          setError('Contraseña mayor a 6 caracteres')
          return
      }
    
      if(resperror !== null){
     //   console.log('Error de acceso')
        setError('Error de acceso')
        // return
      }

   //   console.log('Validando usuario...')
      setError(null)
      Login()
  }

    const Login = React.useCallback(async()=>{
 //   console.log('is login')
    setSuccess(null)
    
    try{
      dispatch(ingresoUsuarioLogin(email, pass))
    }catch(error){
      console.log(error)
    }

  })

  

      return (
        <Container className='formcontent ' id="ajustefromIni">
        
        <Form onSubmit={procesarDatos}>
                        {
                            success ? (
                                <div className="alert alert-success">
                                {success}
                            </div>
                            ):null
                        }
                        {
                            error ? (
                                <div className="alert alert-danger">
                              {error}
                                </div>
                            ) : null
                        }
                        {
                          resperror? (
                            <div className="alert alert-danger">
                            {resperror}
                              </div>
                          ) : null
                        }
          <Form.Group widths='equal'>
            <Form.Field 
              iconPosition='left' 
              control={Input}
              type="email" 
              label='Correo electronico'
              placeholder='email@dominio.com'
              icon='at'
              onChange={e => setEmail(e.target.value)}
              value ={email}
              /> 
          </Form.Group>
          <Form.Group widths='equal'>
          <Form.Field 
              control={Input}
              type = "password"
              label='Contraseña'
              placeholder='*************'
              iconPosition='left'
              icon = 'lock' 
              onChange={e => setPass(e.target.value)}
              value ={pass}
            />
          </Form.Group>
          <Form.Group widths='equal'>
          
          </Form.Group>
          <button 
              className=" btn-bicci btn-bicci-prupura "
              type="sutmit">
              Entrar
          </button>
          <NavLink to="/resset-pass" exact>
          <button className=" btn-bicci btn-bicci-prupura" >
                Crear o recuperar contraseña 
          </button>  
          </NavLink>
         </Form>
         <div>
              <BtnAceceder></BtnAceceder> 
              <div >
           
                            {/*   
                  <BtnRegistrarme></BtnRegistrarme> */}
              </div>
        </div>
        </Container>
      )
    
  }

  export default FormLogin;