import axios from 'axios'
import {firebase, db} from '../firebase'

// constantes
const dataInicial = {
    count: 0,
    next: null,
    previous: null,
    results: []
}

// types
const OBTENER_RIDERS_EXITO = 'OBTENER_RIDERS_EXITO'
const SIGUIENTE_RIDERS_EXITO = 'SIGUIENTE_RIDERS_EXITO'
const RIDER_INFO_EXITO = 'RIDER_INFO_EXITO'
const UPDATE_RIDER_EXITO = 'UPDATE_RIDER_EXITO'

// reducer
export default function adminRiders(state = dataInicial, action){
    switch(action.type){
        case OBTENER_RIDERS_EXITO:
            return {...state, ...action.payload}
        case SIGUIENTE_RIDERS_EXITO:
            return {...state, ...action.payload}
        case RIDER_INFO_EXITO:
            return {...state, unPokemon: action.payload}
        case UPDATE_RIDER_EXITO:
            return {...state}
        default:
            return state
    }
}

// acciones

export const unRiderDetalleAccion = (id) => async (dispatch) => {

    // if(localStorage.getItem(url)){

    //     dispatch({
    //         type: RIDER_INFO_EXITO,
    //         payload: JSON.parse(localStorage.getItem(url))
    //     })
    //     console.log('desde localstorage')

    //     return
    // }

    try {
        console.log('desde api')
    //   const res = await axios.get(url) 
    //    console.log(res.data)
    console.log('datos desde la api')
    const res = await db.collection("drivers")
    .doc(id)
    //.where('active','==',true)
    //.limit(100)
    .get();
     // console.log('total : '+data.length)
       dispatch({
           type: RIDER_INFO_EXITO,
           payload: {
               nombre: res.data.name,
               ancho: res.data.weight,
               alto: res.data.height,
               foto: res.data.sprites.front_default
           }
       })

    //    localStorage.setItem(url, JSON.stringify({
    //         nombre: res.data.name,
    //         ancho: res.data.weight,
    //         alto: res.data.height,
    //         foto: res.data.sprites.front_default
    //     }))

    } catch (error) {
        console.log(error)
    }

}

export const obtenerRidersAccion = () => async (dispatch) => {

     if(localStorage.getItem('offset=0')){
         console.log('datos guardados')
        // console.log(JSON.parse(JSON.stringify(localStorage)))
         dispatch({
             type: OBTENER_RIDERS_EXITO,
             payload: JSON.parse(localStorage.getItem('offset=0'))
         })
         return
     }

    try {
        console.log('datos desde la api')
                  const accountants = await db.collection("drivers")
                  .where('active','==',true)
                 // .limit(50)
                  .get();
                   // console.log('total : '+data.length)
                  const res = accountants.docs.map(doc => ({id: doc.id, ...doc.data()}))
                  console.log(res)
                  var arrayelement = []
                  accountants.docs.map(item  => (
                    arrayelement.push({...item.data()})
                    ))
                  //setDriversUser(drives)
        dispatch({
            type: OBTENER_RIDERS_EXITO,
            payload: res
        })
        localStorage.setItem('offset=0', JSON.stringify(arrayelement))
    } catch (error) {
        console.log(error)
    }
}

export const actualizarRidersAccion = () => async (dispatch) => {
   try {
       console.log('datos desde la api')
                 const accountants = await db.collection("drivers")
                 .where('active','==',true)
                // .limit(50)
                 .get();
                  // console.log('total : '+data.length)
                 const res = accountants.docs.map(doc => ({id: doc.id, ...doc.data()}))
                 console.log(res)
                 var arrayelement = []
                 accountants.docs.map(item  => (
                    arrayelement.push({...item.data()})
                    ))
                 //setDriversUser(drives)
       dispatch({
           type: OBTENER_RIDERS_EXITO,
           payload: res
       })
       localStorage.setItem('offset=0', JSON.stringify(arrayelement))
   } catch (error) {
       console.log(error)
   }
}

export const actualizarDriverPerformance = (id,data) => async (dispatch) => {
    try {
        console.log('update actualizarDriverPerformance')

        // await db.collection('drivers').doc(id).collection('driverPerformance').doc().set({
        //     data:data,
        // })
        dispatch({
            type: UPDATE_RIDER_EXITO,
        })
    } catch (error) {
        console.log(error)
    }
 }

 export const actualizarScheduleTimes = (id,data) => async (dispatch) => {
    try {
        console.log('update actualizarScheduleTimes')
        // await db.collection('drivers').doc(id).collection('scheduleTimes').doc().set({
        //     data:data,
        // })
        dispatch({
            type: UPDATE_RIDER_EXITO,
        })
    } catch (error) {
        console.log(error)
    }
 }