import React from "react";
//import { AccountProfileDetails } from './Form_micuenta';
import { db } from "../../../firebase";
import { useSelector } from "react-redux";
import dataPerformants from "./dataPerformants.js";
import dataSchedule from "./dataSchedule.js";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import {
  Avatar,
  Box,
  Card,
  CardHeader,
  CardActions,
  CardContent,
  Divider,
  Typography,
} from "@mui/material";
import { Container, Grid, Tab } from "semantic-ui-react";
import {
  ChartJsComponent,
  conditionalRegistration,
} from "react-chartjs3-wrapper";
import { useEffect } from "react";
// import {Bar} from 'react-chartjs-2';
// import { ChartOptions, ChartData } from "chart.js";
import { Chart as ChartJS } from "chart.js/auto";
import QRCode from "react-qr-code";
// import { verificadmin } from '../../../redux/usuarioDucks';
// import FileUploadDocSii from '../../../components/Formularios/FromsUpFiles/FileUploadDocSii';
// import FileUploadLicencia from '../../../components/Formularios/FromsUpFiles/FileUploadLicencia';
// import FileUploadPatente from '../../../components/Formularios/FromsUpFiles/FileUploadPatente';
// import FileUploadRut from '../../../components/Formularios/FromsUpFiles/FileUploadRut';
// import FileUploadVehiculoPadron from '../../../components/Formularios/FromsUpFiles/FileUploadVehiculoPadron';
// import FileUploadVehiculoSoap from '../../../components/Formularios/FromsUpFiles/FileUploadVehiculoSoap';
// import FileUploadVehiculoPatente from '../../../components/Formularios/FromsUpFiles/FileUploadVehiculoPatente';
import BtnAceceder from "../../../components/LoginEmail/BtnAceceder";
require("dotenv").config();

var cuenta = "Sin contenido";
//var facturacion = 'No se muestran datos'
var performance = "No se muestran datos";
var informacionpublica = "No se muestran datos";
//var documentacion = 'No se muestran datos'

const panes = [
  {
    menuItem: "Cuenta",
    render: () => (
      <Tab.Pane className="item" attached={false}>
        {cuenta}
      </Tab.Pane>
    ),
  },
  // {
  //   menuItem: 'Mis pagos',
  //   render: () => <Tab.Pane  className="item" attached={false}>{facturacion}</Tab.Pane>,
  // },
  {
    menuItem: "Desempeño",
    render: () => (
      <Tab.Pane className="item" attached={false}>
        {performance}
      </Tab.Pane>
    ),
  },
  {
    menuItem: "Carnet Rider",
    render: () => (
      <Tab.Pane className="item" attached={false}>
        {informacionpublica}
      </Tab.Pane>
    ),
  },
  // {
  //   menuItem: 'Documentacion',
  //   render: () => <Tab.Pane   className="item"  attached={false}>{documentacion}</Tab.Pane>,
  // },
];
const Micuenta = (props) => {
  const userUid = useSelector((store) => store.usuario.user.uid);

  const [isAdmin, setIsAdmin] = React.useState(false);
  const [isRider, setIsRider] = React.useState(false);
  //    const[userDisplayName,setDisplayName] = React.useState()

  const [active, setActive] = React.useState("");
  //   const[ address, setAddress]   = React.useState('')
  //   const[ appVersion, setAppVersion]   = React.useState('')
  const [avatar, setAvatar] = React.useState("");
  const [bicciNumber, setBicciNumber] = React.useState("");
  const [createdAt, setCreatedAt] = React.useState("");
  //    const[ currentInvitation, setCurrentInvitation]   = React.useState('')
  //    const[ currentRequest, setCurrentRequest]   = React.useState('')
  //    const[ device, setDevice]   = React.useState('')
  const [dni, setDni] = React.useState("");
  //    const[ dniFile, setDniFile]   = React.useState('')
  // const[ driverLicence, setDriverLicence]   = React.useState('')
  // const[ driverLocation, setDriverLocation]   = React.useState('')
  const [email, setEmail] = React.useState("");
  // const[ id, setRate]   = React.useState('')
  // const[ language, setLanguage]   = React.useState('')
  const [name, setName] = React.useState("");
  //    const[ online, setOnline]   = React.useState('')
  const [phone, setPhone] = React.useState("");
  // const[ rate, setRate]   = React.useState('')
  // const[ token, setToken]   = React.useState('')
  // const[ updatedAt, setUpdatedAt]   = React.useState('')
  // const[ dataDriverPerformance, setDataDriverPerformance]   = React.useState('')
  // const[ dataScheduleTimes, setDataScheduleTimes]   = React.useState('')
  const [lastDataDriverPerformance, setLastDataDriverPerformance] =
    React.useState("");
  const [lastDataScheduleTimes, setLastDataScheduleTimes] = React.useState("");

  useEffect(() => {
    obtenerDatosdeCliente(userUid);
    obtenerDriverPerformance(userUid);
    obtenerDriverScheduleTimes(userUid);
    validarAdmin(userUid);

    conditionalRegistration({
      radar: true,
      legend: true,
    });
  }, []);

  //userUid
  const obtenerDatosdeCliente = async (userUid) => {
    try {
      const accountants = await db.collection("drivers").doc(userUid).get();
      var data = accountants.data();
      //      console.log(data)
      setActive(data.active);
      // setAddress(data.address)
      // setAppVersion(data.appVersion)
      setAvatar(data.avatar);
      setBicciNumber(data.bicciNumber);
      setCreatedAt(data.createdAt.seconds);
      // setCurrentInvitation(data.currentInvitation)
      // setCurrentRequest(data.currentRequest)
      // setDevice(data.device)
      setDni(data.dni);
      //      setDniFile(data.dniFile)
      // setDriverLicence(data.driverLicence)
      // setDriverLocation(data.driverLocation)
      setEmail(data.email);
      // setId(data.id)
      // setLanguage(data.language)
      setName(data.name);
      // setOnline(data.online)
      setPhone(data.phone);
      // setRate(data.rate)
      // setToken(data.token)
      // setUpdatedAt(data.updatedAt)
      // setDisplayName( data.data )
      setAvatar(data.avatar);
      setIsRider(true);
    } catch (error) {
      //    console.log(error)
      //  console.log('No es rider')
    }
  };

  const validarAdmin = async (userUid) => {
    try {
      const accountants = await db
        .collection("administrators")
        .doc(userUid)
        .get();

      var data = accountants.data();
      // console.log(data)
      setActive(data.active);
      // setAddress(data.address)
      // setAppVersion(data.appVersion)
      setAvatar(data.avatar);
      setBicciNumber(data.bicciNumber);
      // setCreatedAt(data.createdAt.seconds)
      // setCurrentInvitation(data.currentInvitation)
      // setCurrentRequest(data.currentRequest)
      // setDevice(data.device)
      setDni(data.dni);
      // setDniFile(data.dniFile)
      // setDriverLicence(data.driverLicence)
      // setDriverLocation(data.driverLocation)
      setEmail(data.email);
      // setId(data.id)
      // setLanguage(data.language)
      setName(data.name);
      // setOnline(data.online)
      // setPhone(data.phone)
      // setRate(data.rate)
      // setToken(data.token)
      // setUpdatedAt(data.updatedAt)
      // setDisplayName( data.data )
      setAvatar(data.avatar);

      if (process.env.REACT_APP_RIDERS_ADMIN1 === data.role) {
        setIsAdmin(true);
      }

      // dispatch(verificadmin(userUid))
    } catch (error) {
      //    console.log(error)
      //    console.log('No es Admin')
    }
  };

  const obtenerDriverPerformance = async (userUid) => {
    try {
      const accountantsPerformance = await db
        .collection("drivers")
        .doc(userUid)
        .collection("driverPerformance")
        .get();
      var data = accountantsPerformance.docs;
      //         console.log(data)
      //        setDataDriverPerformance(data)

      const metricsRider = data.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      //console.log(metricsRider)

      metricsRider.sort((a, b) =>
        a.LastPediodoAdd < b.LastPediodoAdd ? 1 : -1
      );
      var lastMetricsRider = metricsRider.slice(0, 4);
      //console.log('AQUI deseméño')
      setLastDataDriverPerformance(lastMetricsRider[0].data);
    } catch (error) {
      //console.log(error)
      //console.log('No existe data accountantsPerformance')
    }
  };

  const obtenerDriverScheduleTimes = async (userUid) => {
    try {
      const accountantsPerformance = await db
        .collection("drivers")
        .doc(userUid)
        .collection("scheduleTimes")
        .get();
      var data = accountantsPerformance.docs;
      //console.log(data)
      //setDataScheduleTimes(data)

      const metricsRiderTimes = data.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      //console.log(metricsRiderTimes)

      metricsRiderTimes.sort((a, b) =>
        a.LastPediodoAdd < b.LastPediodoAdd ? 1 : -1
      );
      var lastMetricsRider = metricsRiderTimes.slice(0, 4);
      //console.log('AQUI Times')
      //console.log(lastMetricsRider[0].data)
      setLastDataScheduleTimes(lastMetricsRider[0].data);
    } catch (error) {
      //console.log('No existe data scheduleTimes')
    }
  };

  // const dataConfigBar = {
  //   labels: [' ', 'Lu 28 - Dom 3', 'Lu 4 - Dom 10', 'Lu 11 - Dom 17', 'Lu 18 - Dom 24', 'Lu 25 - Dom 1', 'Lu 2 - Dom 8'],
  //   datasets: [
  //     {
  //       label: 'Pagos semanales',
  //       backgroundColor: 'rgba(23,88,243,0.2)',
  //       borderColor: 'rgba(23,88,243,1)',
  //       borderWidth: 1,
  //       hoverBackgroundColor: 'rgba(34,75,174,0.4)',
  //       hoverBorderColor: 'rgba(34,75,174,1)',
  //       data: [0, 0, 0, 81000, 56000, 55000, 85000]
  //     }
  //   ]
  // };

  cuenta = (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <table className="table">
            <tbody>
              <tr>
                <th scope="row">Tienda</th>
                <td>value</td>
              </tr>
              <tr>
                <th scope="row">id</th>
                <td>{bicciNumber}</td>
              </tr>
              <tr>
                <th scope="row">Fecha de alta</th>
                <td>{createdAt}</td>
              </tr>
              <tr>
                <th scope="row">Nombre</th>
                <td>{name}</td>
              </tr>
              {/* <tr>
                              <th scope="row">Apellido
                              </th>
                              <td>value</td>
                            </tr> */}
              <tr>
                <th scope="row">Teléfono</th>
                <td>{phone}</td>
              </tr>
              <tr>
                <th scope="row">Email</th>
                <td>{email}</td>
              </tr>
              <tr>
                <th scope="row">Nacionalidad</th>
                <td>value</td>
              </tr>
              <tr>
                <th scope="row">Rut / DNI / Pasaporte</th>
                <td>{dni}</td>
              </tr>
              <tr>
                <th scope="row">Correo Rider</th>
                <td>@rider.bicci.cl</td>
              </tr>
            </tbody>
          </table>
        </Box>
      </CardContent>
      <Divider />
      <CardActions></CardActions>
    </Card>
  );
  // facturacion = ( <Card >
  //   <CardContent>
  //     <Box
  //       sx={{
  //         alignItems: 'center',
  //         display: 'flex',
  //         flexDirection: 'column'
  //       }}
  //     >
  //      <div className='Barwhidth'>
  //   <h5>Resumen Semanal</h5>
  //   <Bar
  //     data={dataConfigBar}
  //     width={100}
  //     height={55}
  //     options={{}}
  //   />
  // </div>
  // <table className="table">

  //                 <thead>
  //                 <tr>
  //                    <th scope="row">Periodo
  //                    </th>
  //                    <th>Pago</th>
  //                  </tr>
  //                 </thead>
  //                <tbody>
  //                  <tr>
  //                    <th scope="row">Lu 28 - Dom 3
  //                    </th>
  //                    <td>$0</td>
  //                  </tr>
  //                  <tr>
  //                    <th scope="row">Lu 4 - Dom 10
  //                    </th>
  //                    <td>$0</td>
  //                  </tr>
  //                  <tr>
  //                    <th scope="row">Lu 11 - Dom 17
  //                    </th>
  //                    <td>$81000</td>
  //                  </tr>
  //                  <tr>
  //                    <th scope="row">Lu 18 - Dom 24
  //                    </th>
  //                    <td>$56000</td>
  //                  </tr>
  //                  <tr>
  //                    <th scope="row">Lu 25 - Dom 1
  //                    </th>
  //                    <td>$55000</td>
  //                  </tr>
  //                  <tr>
  //                    <th scope="row">Lu 2 - Dom 8
  //                    </th>
  //                    <td>$85500</td>
  //                  </tr>

  //                </tbody>
  //              </table>

  //     </Box>
  //   </CardContent>
  //   <Divider />
  //   <CardActions>

  //   </CardActions>

  //   </Card>)
  informacionpublica = (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Card className="carnetRider">
            <CardContent>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Avatar
                  src={avatar}
                  sx={{
                    height: 64,
                    mb: 2,
                    width: 64,
                  }}
                />

                <strong>{name}</strong>

                <strong>{dni}</strong>
                <br></br>
              </Box>
            </CardContent>
            <Divider />
            <div className="centercontent">
              <strong>ID:{bicciNumber}</strong>
            </div>
            <CardActions>
              <div className="centercontent">
                <div className="borderCode">
                  <QRCode
                    value={`https://get-bicci.web.app/consulta/${userUid}`}
                    size={150}
                    level="H"
                    fgColor="#741ab0"
                  />
                </div>
              </div>
            </CardActions>
            <div className="centercontent contac-data">
              Empresa: Bicci Delivery
              <br></br>
              CONTACTO <br></br>
              +56 9 4595 0049
              <br></br>
              <NavLink to={`consulta/${userUid}`}>Ver en web</NavLink>
            </div>
          </Card>
        </Box>
      </CardContent>
      <Divider />
      <CardActions></CardActions>
    </Card>
  );
  performance = (
    <Card>
      <CardContent>
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Card>
            <CardContent>
              <Box
                sx={{
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ChartJsComponent
                  type="radar"
                  data={dataSchedule(lastDataScheduleTimes).data}
                />
              </Box>
            </CardContent>
            <Divider />
            <CardActions></CardActions>
          </Card>
        </Box>
      </CardContent>
      <Divider />
      <CardActions></CardActions>
    </Card>
  );

  // console.log('la ul de la imagen es' + urlImagen);

  // Almacenamiento
  // Imagen Foto perfil - /thumbnails/LVY9zIL1OAbep6OvrrjEovLsKRD2
  //                           - antecedentesFile

  // FileUploadRut             - dniFile

  // FileUploadLicencia        - driverLicence
  // FileUploadPatente         +
  // FileUploadDocSii          + socSiiFile
  // FileUploadVehiculoPadron  + VehiculoPadronFile
  // FileUploadVehiculoPatente + VehiculoPatenteFile
  // FileUploadVehiculoSoap    + VehiculoPadronSoapFile

  //       documentacion = (<Card><CardContent><div className='documentacioncontainer'>

  //             <FileUploadRut
  //             userUid={userUid}
  //             authorized={'authorized'}
  //             prefix={'rut'}
  //             dniFile={dniFile}
  //             prefixf={'dniFile'}
  //             title={'Rut'}
  //             descripcion={'Subir rut (Pdf, Jpg)'}
  //             objRef='imagen1'
  //             driverUid={userUid}/>

  // {/*
  //             <FileUploadPatente
  //             userUid={'userUid'}
  //             authorized={'authorized'}
  //             prefix={'patente'}
  //             prefixf={'drivers'}
  //             title={'Patente'}
  //             descripcion={'Subir Patente (Pdf, Jpg)'}
  //             objRef='imagen5'
  //             driverUid={'26456608-8'}/>

  //           <FileUploadDocSii
  //             userUid={'userUid'}
  //             authorized={'authorized'}
  //             prefix={'docsii'}
  //             prefixf={'drivers'}
  //             title={'DocSII'}
  //             descripcion={'Subir DocSII (Pdf, Jpg)'}
  //             objRef='imagen5'
  //             driverUid={'26456608-8'}/>

  //             <FileUploadLicencia
  //             userUid={'userUid'}
  //             authorized={'authorized'}
  //             prefix={'licencia'}
  //             prefixf={'drivers'}
  //             title={'Licencia'}
  //             descripcion={'Subir Licencia (Pdf, Jpg)'}
  //             objRef='imagen5'
  //             driverUid={'26456608-8'}/>

  //             <FileUploadVehiculoPadron
  //             userUid={'userUid'}
  //             authorized={'authorized'}
  //             prefix={'padron'}
  //             prefixf={'drivers'}
  //             title={'Padron'}
  //             descripcion={'Subir Padron (Pdf, Jpg)'}
  //             objRef='imagen5'
  //             driverUid={'26456608-8'}/>

  //           <FileUploadVehiculoSoap
  //             userUid={'userUid'}
  //             authorized={'authorized'}
  //             prefix={'soap'}
  //             prefixf={'drivers'}
  //             title={'Soap'}
  //             descripcion={'Subir Soap (Pdf, Jpg)'}
  //             objRef='imagen5'
  //             driverUid={'26456608-8'}/>

  //            */}

  //         </div></CardContent></Card>)
  // console.log(userUid)
  // console.log(props)

  const handleClick = (e) => {
    props.history.push("/admin");
  };
  return (
    <Container>
      <Grid celled="internally" id="mi-cuentaContent">
        {isAdmin === true ? (
          <div className="div-notice alert alert-success">
            <span className="">
              Hola {name}, estás habilitado como administrador. Ve el gestor de
              riders...
              <button onClick={handleClick} className="btn btn-bicci">
                Admin
              </button>
            </span>
          </div>
        ) : (
          <></>
        )}

        {isRider === true ? (
          <></>
        ) : (
          <div className="div-notice alert alert-danger">
            <span className="">
              Hola {name}, no estás registrado como Bicci-Rider con este email.
              Instala la aplicación para crear una cuenta rider
            </span>
          </div>
        )}

        <div>
          <h2>Mi cuenta</h2>
        </div>
        <Grid.Row>
          <Grid.Column
            computer={3}
            mobile={16}
            tablet={16}
            id="mi-cuentaContainer"
            className="dash-columns"
          >
            <Card>
              <CardContent>
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    width: "300px",
                  }}
                >
                  <Avatar
                    src={avatar}
                    sx={{
                      height: 64,
                      mb: 2,
                      width: 64,
                    }}
                  />
                  <Typography color="textPrimary" gutterBottom variant="h5">
                    {name}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {email}
                  </Typography>
                </Box>
              </CardContent>
              <Divider />
              <CardActions>
                <div className="alignItemscenter">
                  {active === true ? (
                    <span className="activo">Activo</span>
                  ) : (
                    <span className="inactivo">Inactivo</span>
                  )}{" "}
                </div>
              </CardActions>
            </Card>
            <br></br>

            <br></br>
            {/* <AccountProfileDetails  />  */}
            <br></br>
            {isRider === true ? (
              <Card>
                <CardContent>
                  <Box
                    sx={{
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div>
                      <ChartJsComponent
                        type="radar"
                        data={dataPerformants(lastDataDriverPerformance).data}
                        pointerEvents="false"

                        // options={config.options}
                      />
                      {/* <Radar data={config.data} options={config.options} /> */}
                    </div>
                  </Box>
                </CardContent>
                <Divider />
                <CardActions></CardActions>
              </Card>
            ) : (
              <>
                <div className="max350px">
                  <BtnAceceder />
                </div>
              </>
            )}
          </Grid.Column>

          <Grid.Column
            computer={10}
            mobile={16}
            tablet={16}
            className="dash-container-center dash-columns"
          >
            {isRider === true ? (
              <Card>
                <CardHeader title={<span>Detalles de cuenta</span>} />
                <Tab
                  className="tabular menu"
                  menu={{ secondary: true }}
                  panes={panes}
                ></Tab>
              </Card>
            ) : (
              <></>
            )}
          </Grid.Column>
          <Grid.Column
            computer={3}
            mobile={16}
            tablet={16}
            className="dash-container-colmun dash-columns dash-columns-mini"
          >
            <Grid.Row className="bottomargin"></Grid.Row>

            <Grid.Row className="bottomargin"></Grid.Row>

            <Grid.Row className="bottomargin"></Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default Micuenta;
