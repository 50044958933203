import {createStore, combineReducers, compose, applyMiddleware} from 'redux'
import thunk from 'redux-thunk'


import usuarioReducer, {leerUsuarioActivoAccion} from './usuarioDucks'
import accionesDriverEdit from './acccionesClientes'
import adminRiders from './adminRiders' 

const rootReducer = combineReducers({
    usuario: usuarioReducer,
    driver:accionesDriverEdit,
    adminriders:adminRiders,
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore(){
    const store = createStore( rootReducer,  composeEnhancers( applyMiddleware(thunk) ))
    leerUsuarioActivoAccion()(store.dispatch)
    return store;
}