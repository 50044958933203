import React from 'react';
import { useSelector} from 'react-redux'
const Logo = () => {
  const activo = useSelector(store => store.usuario.activo)
  return (
    <div className= 
    {
      activo ? (
        'logo_content_dash'
      ) : (
        'logo_content'
      )
      }
    >
        <img src="/logo.png" alt="Bicci" />
    </div>

  );
};

export default Logo;
